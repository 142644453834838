import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./router";
import { setTitle, canTurnTo } from "@/libs/util";
import ViewUIPlus from "view-ui-plus";

const router = createRouter({
  routes,
  history: createWebHashHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});

const turnTo = (to, access, next) => {
  if (canTurnTo(to.name, access, routes)) next(); // 有权限，可访问
  else next({ replace: true, name: "error_401" }); // 无权限，重定向到401页面
};

const LOGIN_PAGE_NAME = "login";

// 路由拦截
router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start();

  if (to.name == LOGIN_PAGE_NAME || to.name == "exchange") {
    next();
  } else {
    let userId = sessionStorage.getItem("userId");
    if (!userId && to.name !== LOGIN_PAGE_NAME) {
      // 未登录且要跳转的页面不是登录页
      next({
        name: LOGIN_PAGE_NAME, // 跳转到登录页
      });
    } else if (userId && to.name == LOGIN_PAGE_NAME) {
      // 已登录且要跳转的页面是登录页
      next({
        name: "home", // 跳转到home页
      });
    } else {
      let access = [sessionStorage.getItem("userRole")];
      turnTo(to, access, next);
    }
  }
});

router.afterEach((to) => {
  ViewUIPlus.LoadingBar.finish();
  setTitle(to, router.app);
  window.scrollTo(0, 0);
});

export default router;
