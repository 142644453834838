<template>
  <div class="layout">
    <Layout style="height: 100%">
      <Header class="layout-header">
        <Menu mode="horizontal" theme="dark" active-name="1">
          <div class="layout-logo"></div>
          <Breadcrumb>
            <BreadcrumbItem
              v-for="(item, index) in breadcrumbList"
              :key="`breadcrumb-${index}`"
            >
              <Icon v-if="!!item.icon" :type="item.icon"></Icon>
              {{ item.title }}
            </BreadcrumbItem>
          </Breadcrumb>
          <div class="layout-nav">
            <Dropdown @on-click="handleDropdownItem">
              <a href="javascript:void(0)" class="login-name">
                管理员 <Icon type="ios-arrow-down"></Icon>
              </a>
              <template #list>
                <DropdownMenu>
                  <!-- <DropdownItem name="personInfo">个人信息</DropdownItem> -->
                  <!-- <DropdownItem name="modifyPwd">修改密码</DropdownItem> -->
                  <DropdownItem name="logout">退出登录</DropdownItem>
                </DropdownMenu>
              </template>
            </Dropdown>
          </div>
        </Menu>
      </Header>
      <Layout class="content-wrapper">
        <sider-menu
          :menuList="menuList"
          :activeName="$route.name"
          @on-select="turnToPage"
        ></sider-menu>
        <Layout
          style="
            padding: 24px;
            width: calc(100vh - 200px);
            box-sizing: border-box;
          "
        >
          <Content class="page-content">
            <router-view />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </div>
</template>
<script>
import { logout } from "@/api/index";
import SiderMenu from "@/components/sider_menu.vue";
import routes from "@/router/router";

export default {
  name: "mainPage",
  components: {
    SiderMenu,
  },
  data() {
    return {
      userName: sessionStorage.getItem("userName"),
      access: sessionStorage.getItem("userRole"),
      menuList: [],
      firstBreadCrumb: {
        title: "首页",
        icon: "md-home",
      },
    };
  },
  computed: {
    // 菜单集合
    menuMap() {
      let map = {};
      for (let i in this.menuList) {
        const menu = this.menuList[i];
        map[menu.name] = menu;
        if (menu.children && menu.children.length > 0) {
          for (let j in menu.children) {
            map[menu.children[j].name] = menu.children[j];
          }
        }
      }
      return map;
    },
    // 面包屑列表
    breadcrumbList() {
      let list = [];

      // 通过当前菜单查询上级菜单
      let menu = this.menuMap[this.$route.meta.parent];
      if (menu) {
        list.push(this.firstBreadCrumb);
        list.push({
          title: menu.meta.title,
          icon: menu.meta.icon,
        });
      }
      // 再加入当前菜单
      list.push({
        title: this.$route.meta.title,
        icon: this.$route.meta.icon,
      });
      return list;
    },
  },
  methods: {
    // 下拉列表选择
    handleDropdownItem(item) {
      switch (item) {
        case "personInfo":
          this.getPersonInfo();
          break;
        case "modifyPwd":
          this.modifyPwd();
          break;
        case "logout":
          this.handleLogout();
          break;
      }
    },
    // 获取个人信息
    getPersonInfo() {
      this.$router.push({
        path: "/personal",
      });
    },
    // 打开修改密码
    modifyPwd() {
      this.$router.push({
        path: "/changePass",
      });
    },
    // 退出登录
    handleLogout() {
      let _this = this;
      logout().then((res) => {
        if (res.code == 200) {
          _this.$Message.success("退出成功");
          sessionStorage.clear();
          _this.$router.push({
            path: "/login",
          });
        }
      });
    },
    // 检查菜单是否可显示，需要判断权限
    canShowMenu(menu) {
      let access =
        menu.meta.access && menu.meta.access.indexOf(this.access) == -1
          ? false
          : true;
      return access && !menu.meta.hideInMenu;
    },
    // 鉴权菜单，过滤无效菜单
    filterMenu(route) {
      if (this.canShowMenu(route)) {
        if (route.children && route.children.length > 0) {
          let children = [];
          for (let i in route.children) {
            let child = this.filterMenu(route.children[i]);
            if (!!child) {
              children.push(child);
            }
          }
          route.children = children;
        }
        return route;
      } else {
        return null;
      }
    },
    // 从路由列表中获取菜单列表
    getMenuList() {
      let menus = JSON.parse(JSON.stringify(routes));
      for (let i in menus) {
        let r = this.filterMenu(menus[i]);
        if (!!r) {
          this.menuList.push(r);
        }
      }
    },
    // 跳转页面
    turnToPage(name) {
      this.$router.push({
        name,
      });
    },
  },
  mounted() {
    this.getMenuList();
  },
};
</script>
<style>
.layout {
  border: 1px solid #d7dde4;
  background: #000000;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.ivu-layout-header {
  padding: 0 20px !important;
}
.layout-logo {
  color: white;
  font-size: 26px;
  cursor: pointer;
  display: inline-block;
}
.layout-nav {
  width: 90px;
  margin: 0 auto;
  margin-right: 20px;
  display: inline-block;
  float: right;
}
.login-name {
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.content-wrapper {
  flex-direction: row !important;
  height: calc(100vh - 64px);
}
.page-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.ivu-breadcrumb {
  color: #fff !important;
  display: inline-block;
  margin-left: 40px;
}
.ivu-breadcrumb > span:last-child {
  color: #fff !important;
}
</style>
