import axios from "axios";
import router from "@/router";
import { Message } from "view-ui-plus";

// 超时设定
axios.defaults.timeout = 150000;
axios.interceptors.request.use(
  (config) => {
    return config;
    dcfvxdcxfv;
  },
  (err) => {
    Message.error("请求超时");
    return Promise.resolve(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    console.log("response", response);

    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
      case 400:
        // 未登录 清除已登录状态
        sessionStorage.clear();
        Message.error("您未登录或登录超时，请重新登录");
        router.push({ path: "/login" });
        break;
      case 403:
        // 没有权限
        if (data.msg !== null) {
          Message.error(data.msg);
        } else {
          Message.error("未知错误");
        }
        break;
      default:
        return data;
    }
    return data;
  },
  (err) => {
    // 返回状态码不为200时候的错误处理
    // Message.error(err.toString());
    return Promise.resolve(err);
  }
);

let base = "";

/********* 管理员 ********/
export const login = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/login`,
    params: param,
    method: "post",
  });
};
export const logout = () => {
  return axios.request({
    url: `${base}/api/manage/user/logout`,
    method: "get",
  });
};
export const getCurrentUser = () => {
  return axios.request({
    url: `${base}/api/manage/user/current`,
    method: "get",
  });
};
export const getManageUserList = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/list`,
    method: "post",
    params: param,
  });
};
export const getManageUserDetail = (id) => {
  return axios.request({
    url: `${base}/api/manage/user/` + id,
    method: "get",
  });
};
export const saveManageUser = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/save`,
    method: "post",
    data: param,
  });
};
export const deleteManageUser = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/delete`,
    method: "get",
    params: param,
  });
};
export const modifyPwd = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/modify/pwd`,
    method: "post",
    params: param,
  });
};
export const statistic = () => {
  return axios.request({
    url: `${base}/api/analysis/statistic`,
    method: "get",
  });
};

/********* 用户管理 ********/
export const getUserList = (param) => {
  return axios.request({
    url: `${base}/api/user/list`,
    method: "post",
    params: param,
  });
};
export const getUserDetail = (id) => {
  return axios.request({
    url: `${base}/api/user/detail/` + id,
    method: "get",
  });
};
export const saveCode = (data) => {
  return axios.request({
    url: `${base}/api/manage/user/gift`,
    method: "post",
    data,
  });
};
export const getGiftList = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/list`,
    method: "get",
    params: param,
  });
};
export const sendGift = (param) => {
  return axios.request({
    url: `${base}/api/manage/user/send`,
    method: "get",
    params: param,
  });
};
/********* 机构管理 ********/
export const getBranchTree = (param) => {
  return axios.request({
    url: `${base}/api/branch/treeBranch`,
    method: "get",
    params: param,
  });
};
export const getBranchList = (param) => {
  return axios.request({
    url: `${base}/api/branch/list`,
    method: "get",
    params: param,
  });
};
export const getBranchDetail = (param) => {
  return axios.request({
    url: `${base}/api/branch/detail`,
    method: "get",
    params: param,
  });
};
export const saveBranch = (param) => {
  return axios.request({
    url: `${base}/api/branch/save`,
    method: "post",
    data: param,
  });
};
export const deleteBranch = (param) => {
  return axios.request({
    url: `${base}/api/branch/delete`,
    method: "get",
    params: param,
  });
};

/********* 科目管理 ********/
export const getSubjectTree = (param) => {
  return axios.request({
    url: `${base}/api/subject/treeSubject`,
    method: "get",
    params: param,
  });
};
export const getSubjectDetail = (param) => {
  return axios.request({
    url: `${base}/api/subject/detail`,
    method: "get",
    params: param,
  });
};
export const saveSubject = (param) => {
  return axios.request({
    url: `${base}/api/subject/save`,
    method: "post",
    data: param,
  });
};
export const deleteSubject = (param) => {
  return axios.request({
    url: `${base}/api/subject/delete`,
    method: "get",
    params: param,
  });
};

/********* 课程管理 ********/
export const getCourseList = (param) => {
  return axios.request({
    url: `${base}/api/course/list`,
    method: "get",
    params: param,
  });
};
export const getCourseDetail = (param) => {
  return axios.request({
    url: `${base}/api/course/detail`,
    method: "get",
    params: param,
  });
};
export const saveCourse = (param) => {
  return axios.request({
    url: `${base}/api/course/save`,
    method: "post",
    data: param,
  });
};
export const deleteCourse = (param) => {
  return axios.request({
    url: `${base}/api/course/delete`,
    method: "get",
    params: param,
  });
};
export const auditCourse = (param) => {
  return axios.request({
    url: `${base}/api/course/changeStatus`,
    method: "get",
    params: param,
  });
};

/********* 视频管理 ********/
export const getVideoList = (param) => {
  return axios.request({
    url: `${base}/api/video/list`,
    method: "get",
    params: param,
  });
};
export const getVideoDetail = (param) => {
  return axios.request({
    url: `${base}/api/video/detail`,
    method: "get",
    params: param,
  });
};
export const saveVideo = (param) => {
  return axios.request({
    url: `${base}/api/video/save`,
    method: "post",
    data: param,
  });
};
export const deleteVideo = (param) => {
  return axios.request({
    url: `${base}/api/video/delete`,
    method: "get",
    params: param,
  });
};
export const changeVideoStatus = (param) => {
  return axios.request({
    url: `${base}/api/video/changeStatus`,
    method: "get",
    params: param,
  });
};

/********* 课程视频管理 ********/
export const getCourseVideoList = (param) => {
  return axios.request({
    url: `${base}/api/courseVideo/listVideo`,
    method: "get",
    params: param,
  });
};
export const getMayCourseVideoList = (param) => {
  return axios.request({
    url: `${base}/api/courseVideo/listMayVideo`,
    method: "get",
    params: param,
  });
};
export const getCourseVideoDetail = (param) => {
  return axios.request({
    url: `${base}/api/courseVideo/detail`,
    method: "get",
    params: param,
  });
};
export const saveCourseVideo = (param) => {
  return axios.request({
    url: `${base}/api/courseVideo/save`,
    method: "post",
    data: param,
  });
};
export const deleteCourseVideo = (param) => {
  return axios.request({
    url: `${base}/api/courseVideo/delete`,
    method: "get",
    params: param,
  });
};

/********* 学员管理 ********/
export const getLearnerList = (param) => {
  return axios.request({
    url: `${base}/api/learner/list`,
    method: "get",
    params: param,
  });
};
export const getLearnerDetail = (param) => {
  return axios.request({
    url: `${base}/api/learner/detail`,
    method: "get",
    params: param,
  });
};
export const saveLearner = (param) => {
  return axios.request({
    url: `${base}/api/learner/save`,
    method: "post",
    data: param,
  });
};
export const deleteLearner = (param) => {
  return axios.request({
    url: `${base}/api/learner/delete`,
    method: "get",
    params: param,
  });
};
export const auditLearner = (param) => {
  return axios.request({
    url: `${base}/api/learner/changeStatus`,
    method: "get",
    params: param,
  });
};

/********* 岗位管理 ********/
export const getStationList = (param) => {
  return axios.request({
    url: `${base}/api/station/list`,
    method: "get",
    params: param,
  });
};
export const getStationDetail = (param) => {
  return axios.request({
    url: `${base}/api/station/detail`,
    method: "get",
    params: param,
  });
};
export const saveStation = (param) => {
  return axios.request({
    url: `${base}/api/station/save`,
    method: "post",
    data: param,
  });
};
export const deleteStation = (param) => {
  return axios.request({
    url: `${base}/api/station/delete`,
    method: "get",
    params: param,
  });
};
export const getBranchStationList = () => {
  return axios.request({
    url: `${base}/api/station/getBranchStationList`,
    method: "get",
  });
};
export const getStationCourseList = (param) => {
  return axios.request({
    url: `${base}/api/station/getStationCourseList`,
    method: "get",
    params: param,
  });
};
export const saveStationCourse = (param) => {
  return axios.request({
    url: `${base}/api/station/saveStationCourse`,
    method: "post",
    data: param,
  });
};
export const deleteStationCourse = (param) => {
  return axios.request({
    url: `${base}/api/station/deleteStationCourse`,
    method: "get",
    params: param,
  });
};

/********* 订单管理 ********/
export const getOrderList = (param) => {
  return axios.request({
    url: `${base}/api/order/list`,
    method: "get",
    params: param,
  });
};
export const getOrderDetail = (param) => {
  return axios.request({
    url: `${base}/api/order/detail`,
    method: "get",
    params: param,
  });
};

/********* 积分管理 ********/
export const getPointList = (param) => {
  return axios.request({
    url: `${base}/api/learnerPoint/list`,
    method: "get",
    params: param,
  });
};

/********* 题库管理 ********/
export const getBankList = (param) => {
  return axios.request({
    url: `${base}/api/question/bank/list`,
    method: "post",
    params: param,
  });
};
export const getBankDetail = (param) => {
  return axios.request({
    url: `${base}/api/question/bank/detail`,
    method: "get",
    params: param,
  });
};
export const saveBank = (param) => {
  return axios.request({
    url: `${base}/api/question/bank/save`,
    method: "post",
    data: param,
  });
};
export const deleteBank = (param) => {
  return axios.request({
    url: `${base}/api/question/bank/delete`,
    method: "get",
    params: param,
  });
};

/********* 题目管理 ********/
export const getQuestionList = (param) => {
  return axios.request({
    url: `${base}/api/question/list`,
    method: "post",
    params: param,
  });
};
export const getPaperQuestionList = (param) => {
  return axios.request({
    url: `${base}/api/question/list/paperQuestion`,
    method: "get",
    params: param,
  });
};
export const getQuestionDetail = (param) => {
  return axios.request({
    url: `${base}/api/question/detail`,
    method: "get",
    params: param,
  });
};
export const saveQuestion = (param) => {
  return axios.request({
    url: `${base}/api/question/save`,
    method: "post",
    data: param,
  });
};
export const deleteQuestion = (param) => {
  return axios.request({
    url: `${base}/api/question/delete`,
    method: "get",
    params: param,
  });
};

/********* 选项管理 ********/
export const getOptionList = (param) => {
  return axios.request({
    url: `${base}/api/question/option/list`,
    method: "get",
    params: param,
  });
};
export const saveOption = (param) => {
  return axios.request({
    url: `${base}/api/question/option/save`,
    method: "post",
    data: param,
  });
};
export const deleteOption = (param) => {
  return axios.request({
    url: `${base}/api/question/option/delete`,
    method: "get",
    params: param,
  });
};

/********* 试卷管理 ********/
export const getPaperList = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/list`,
    method: "post",
    params: param,
  });
};
export const getPaperDetail = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/detail`,
    method: "get",
    params: param,
  });
};
export const savePaper = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/save`,
    method: "post",
    data: param,
  });
};
export const deletePaper = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/delete`,
    method: "get",
    params: param,
  });
};
export const generatePaper = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/generate`,
    method: "get",
    params: param,
  });
};
export const previewPaper = (param) => {
  return axios.request({
    url: `${base}/api/exam/paper/preview`,
    method: "get",
    params: param,
  });
};

/********* 考试管理 ********/
export const getExamList = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/list`,
    method: "post",
    params: param,
  });
};
export const getExamDetail = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/detail`,
    method: "get",
    params: param,
  });
};
export const saveExam = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/save`,
    method: "post",
    data: param,
  });
};
export const deleteExam = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/delete`,
    method: "get",
    params: param,
  });
};
export const examChooseUser = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/user/choose`,
    method: "get",
    params: param,
  });
};
export const examUserChooseList = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/user/choose/list`,
    method: "get",
    params: param,
  });
};
export const examStart = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/start`,
    method: "get",
    params: param,
  });
};
export const examScore = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/score`,
    method: "get",
    params: param,
  });
};
export const examUserList = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/user/list`,
    method: "get",
    params: param,
  });
};
export const examUserDetail = (param) => {
  return axios.request({
    url: `${base}/api/exam/exam/user/detail`,
    method: "get",
    params: param,
  });
};

/********* 直播管理 ********/
export const getLiveList = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/list`,
    method: "get",
    params: param,
  });
};
export const getLiveDetail = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/detail`,
    method: "get",
    params: param,
  });
};
export const saveLive = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/save`,
    method: "post",
    data: param,
  });
};
export const deleteLive = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/delete`,
    method: "get",
    params: param,
  });
};
export const getLiveChooseGroup = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/ListLiveGroup`,
    method: "get",
    params: param,
  });
};
export const saveLiveChooseGroup = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/saveLiveGroup`,
    method: "post",
    params: param,
  });
};
export const getLiveChooseLearner = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/ListLiveLearner`,
    method: "get",
    params: param,
  });
};
export const saveLiveChooseLearner = (param) => {
  return axios.request({
    url: `${base}/api/liveSeeing/saveLiveLearner`,
    method: "post",
    params: param,
  });
};

/********* 统计管理 ********/
export const statisticLearner = (param) => {
  return axios.request({
    url: `${base}/api/learncount/list`,
    method: "get",
    params: param,
  });
};
